<template>
    <div class="breadcrumb-area bg-img" :style="{'background-image':'url('+this.$baseUrl+items.img_url+')'}">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="page-banner text-center">
                        <h1>{{ items.title_chs }}</h1>
                        <h3 style="color:white;">{{ items.title_eng }}</h3>
                        <p style="color:white;">{{ items.sub_title }}</p>
                        <!-- <b-breadcrumb class="page-breadcrumb" :items="items"></b-breadcrumb> -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['items']
    }
</script>