<template>
  <div class="service-details">
    <div class="service-gallery">
      <div class="swiper-container service-gallery__container">
        <div class="swiper-wrapper service-gallery__wrapper">
          <swiper :options="swiperOptions">
            <div
              class="swiper-slide service-gallery__single-slide"
              v-for="(image, id) in schoolDetails.school_env_images"
              :key="id"
            >
              <div class="item">
                <img
                  style="width: 100%; height: 400px; object-fit: cover"
                  :src="this.$baseUrl + image"
                  class="img-fluid"
                  alt="service details image"
                />
              </div>
            </div>
          </swiper>
          
        </div>
      </div>
      <div
        class="ht-swiper-button-prev ht-swiper-button-prev-14 ht-swiper-button-nav"
      >
        <i class="ion-ios-arrow-left"></i>
      </div>
      <div
        class="ht-swiper-button-next ht-swiper-button-next-14 ht-swiper-button-nav"
      >
        <i class="ion-ios-arrow-forward"></i>
      </div>
    </div>

    <!-- <div class="content section-space--top--30">
            <div class="row">
                <div class="col-12">
                    <h2>{{ schoolDetails.name_chs }}</h2>
                    <br>
                    <h5>{{ schoolDetails.sub_title }}</h5>
                    <p >{{ schoolDetails.introduction }}</p>
                </div>
            </div>
        </div> -->
  </div>
</template>

<script>

export default {

  props: ["schoolDetails"],
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        loop: true,
        speed: 1000,
        watchSlidesVisibility: true,
        navigation: {
          nextEl: ".ht-swiper-button-next-14",
          prevEl: ".ht-swiper-button-prev-14",
        },
      },
    };
  },
};
</script>