<template>
  <aside>


    <!-- <div class="accordion" role="tablist">
      <div v-for="(type, i) in schoolSubjects" :key="i">
        <b-card
          no-body
          class="mb-1"
          v-if="schoolSubjects[i].subjects.length != 0"
        >
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="'accordion-' + i" variant="info">{{
              type.type
            }}</b-button>
          </b-card-header>
          <b-collapse
            :id="'accordion-'+i"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <ul class="sidebar-list">
                <li
                  v-for="(subject, id) in schoolSubjects[i].subjects"
                  :key="id"
                >
                  <router-link to="#">
                    {{ subject.name_chs }}</router-link>
                </li>
              </ul>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div> -->

    <div class="sidebar-widget">
      <h3 class="sidebar-title">下载学校简介</h3>
      <ul class="sidebar-list">
        <li>
          <a href="#">
            <i class="fa fa-file-pdf-o"></i>
            Brochures.PDF
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa fa-file-word-o"></i>
            Brochures.DOC
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  props: ["schoolSubjects"],
};
</script>